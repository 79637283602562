import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Todo } from '@/types/todo';

const initialState: Todo.Item[] = [];

export const todoItemSlice = createSlice({
  name: 'todo',
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<{ title: string }>) => {
      const todoItem: Todo.Item = {
        id: Math.random() * 100,
        title: action.payload.title,
        completed: false,
      };
      state.push(todoItem);
    },

    removeItem: (state, action: PayloadAction<{ id: number }>) => {
      return state.filter((item) => item.id !== action.payload.id);
    },

    completeItem: (state, action: PayloadAction<{ id: number }>) => {
      return state.map((item) =>
        item.id === action.payload.id ? { ...item, completed: true } : item
      );
    },

    unCompleteItem: (state, action: PayloadAction<{ id: number }>) => {
      return state.map((item) =>
        item.id === action.payload.id ? { ...item, completed: false } : item
      );
    },

    editItemTitle: (state, action: PayloadAction<{ id: number; title: string }>) => {
      return state.map((item) =>
        item.id === action.payload.id ? { ...item, title: action.payload.title } : item
      );
    },

    editItemDescription: (state, action: PayloadAction<{ id: number; description: string }>) => {
      return state.map((item) =>
        item.id === action.payload.id ? { ...item, description: action.payload.description } : item
      );
    },
  },
});

export const todoReducer = todoItemSlice.reducer;
export const todoActions = todoItemSlice.actions;
