import * as React from "react";
import { TodoList } from "@/components/TodoList";
import { useTypedSelector } from "@/hooks/useTypedSelectors";
import { Todo } from "@/types/todo";
import { AddTodoItem } from "@/components/AddTodoItem";

const App = () => {
  const { todos } = useTypedSelector((state) => state);

  const unCompletedItems: Todo.Item[] = React.useMemo(() => {
    const unCompletedItems: Todo.Item[] = [];
    todos?.forEach((item) => {
      if (!item.completed) {
        unCompletedItems.push(item);
      }
    });

    return unCompletedItems;
  }, [todos]);

  const completedItems: Todo.Item[] = React.useMemo(() => {
    const completedItems: Todo.Item[] = [];
    todos?.forEach((item) => {
      if (item.completed) {
        completedItems.push(item);
      }
    });

    return completedItems;
  }, [todos]);

  return (
    <main className="p-6">
      <p>Hello, bitches and bastards!</p>
      <p>It's your deals list</p>
      <AddTodoItem />
      <TodoList items={unCompletedItems} />
      {completedItems?.length ? (
        <div className="my-10">
          <h2 className="mb-6 text-xl">Completed deals</h2>
          <TodoList items={completedItems} />
        </div>
      ) : null}
    </main>
  );
};

export default App;
