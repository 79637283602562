import { Todo } from '@/types/todo';
import { TodoItem } from '@/components/TodoItem';

export const TodoList = ({ items }: Todo.List) => {
  return (
    <div className="grid gap-y-3">
      {items.map((item, key: number) => (
        <TodoItem {...item} key={key} />
      ))}
    </div>
  );
};
