import { useActions } from "@/hooks/useActions";
import { Todo } from "@/types/todo";
import React from "react";
import {
  PencilSquareIcon,
  PencilIcon,
  BoltIcon,
  TrashIcon,
  BoltSlashIcon,
  FingerPrintIcon,
} from "@heroicons/react/24/outline";

export const TodoItem = (item: Todo.Item) => {
  const {
    removeItem,
    completeItem,
    unCompleteItem,
    editItemDescription,
    editItemTitle,
  } = useActions();

  const [openTodoItem, setOpenTodoItem] = React.useState<boolean>(false);
  const [addDescription, setAddDescription] = React.useState<boolean>(false);
  const [editTitle, setEditTitle] = React.useState<boolean>(false);
  const [description, setDescription] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>(item.title);

  function handleDescription(event: React.FormEvent<HTMLTextAreaElement>) {
    setDescription(event.currentTarget.value);
  }

  function handleTitle(event: React.FormEvent<HTMLInputElement>) {
    setTitle(event.currentTarget.value);
  }

  const saveDescriptionHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    editItemDescription({ description, id: item.id });
    setAddDescription(false);
  };

  const saveTitleHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    editItemTitle({ title, id: item.id });
    setEditTitle(false);
  };

  return (
    <article className={`border p-4 ${item.completed ? "opacity-40" : null}`}>
      <div className="flex justify-between gap-x-6">
        {editTitle ? (
          <form
            onSubmit={(event) => {
              saveTitleHandler(event);
            }}
            className="w-full"
          >
            <input
              type="text"
              onChange={handleTitle}
              value={title}
              className="w-full border"
            />
          </form>
        ) : (
          <h1
            onClick={() =>
              item.completed
                ? unCompleteItem({ id: item.id })
                : setOpenTodoItem(!openTodoItem)
            }
            className="flex-1 hover:cursor-pointer"
          >
            {item.completed ? <s>{item.title}</s> : item.title}
          </h1>
        )}
        <div className="grid grid-flow-col gap-x-4">
          <button
            onClick={() => {
              setEditTitle(true);
            }}
            title="Edit title of task"
          >
            <PencilSquareIcon className="w-6 h-6" />
          </button>

          {!item.completed ? (
            <button
              onClick={() => {
                completeItem({ id: item.id });
              }}
              title="Complete task"
            >
              <BoltIcon className="w-6 h-6" />
            </button>
          ) : (
            <button
              onClick={() => {
                unCompleteItem({ id: item.id });
              }}
              title="Uncomplete title"
            >
              <BoltSlashIcon className="w-6 h-6" />
            </button>
          )}
          <button
            onClick={() => {
              removeItem({ id: item.id });
            }}
            title="Remove task"
          >
            <TrashIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
      {openTodoItem ? (
        <div className="grid grid-flow-row my-4 gap-y-4">
          <h4>Description</h4>
          <p className="relative p-4 bg-gray-200 rounded-xl">
            {item.description && !addDescription ? (
              <>
                <small>{item.description}</small>
                <PencilIcon
                  onClick={() => setAddDescription(true)}
                  className="absolute w-3 h-3 underline cursor-pointer right-3 top-3"
                  title="Change description of task"
                />
              </>
            ) : addDescription ? (
              <form
                className="grid w-full gap-y-3"
                onSubmit={(event) => {
                  saveDescriptionHandler(event);
                }}
              >
                <textarea
                  placeholder="Please, enter shit for this item and click Save after"
                  onChange={handleDescription}
                  value={description}
                ></textarea>
                <button title="Save this shit">
                  <FingerPrintIcon className="w-4 h-4" />
                </button>
              </form>
            ) : (
              <small>
                Description is null, but you can{" "}
                <button
                  onClick={() => setAddDescription(true)}
                  className="underline"
                >
                  add it
                </button>
              </small>
            )}
          </p>
        </div>
      ) : null}
    </article>
  );
};
