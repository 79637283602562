import { useActions } from '@/hooks/useActions';
import * as React from 'react';

export const AddTodoItem = () => {
  const { addItem } = useActions();
  const [todoTitle, setTodoTitle] = React.useState<string>('');

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    setTodoTitle(event.currentTarget.value);
  }

  function addTodoHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (todoTitle) {
      addItem({
        title: todoTitle,
      });
      setTodoTitle('');
    }
  }
  return (
    <form onSubmit={addTodoHandler}>
      <input
        type="text"
        placeholder="What are you saying?"
        onChange={handleChange}
        value={todoTitle}
        className="border w-full my-3 h-14 p-4 bg-lime-100 text-emerald-900 placeholder:text-teal-600"
      />
    </form>
  );
};
