import { configureStore } from '@reduxjs/toolkit';
import { todoReducer } from './todoItem.slice';

const reducer = {
  todos: todoReducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});

export type TypeRootState = ReturnType<typeof store.getState>;
